import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/SEO"

import Container from "../components/Container/Container"
import Products from "../components/Products/Products"

const Produkty = () => (
  <Layout pageInfo={{ pageName: "produkty" }}>
    <SEO title="Produkty" />
    <Container>
      <Products>
        <p>
          Wymienniki ciepła Alfa Laval są urządzeniami szeroko stosowanymi w
          przemyśle HVAC. Stosuje się je w systemach i sieciach chłodzenia oraz
          systemach ogrzewania wspierających różnorodne procesy przemysłowe.
          Wymienniki ciepła biorą udział w procesach takich, jak: przygotowanie
          ciepłej wody użytkowej w budynkach, chłodzenie budynków (w tym
          wieżowców), zapewnianie odpowiedniej temperatury wody w basenach, są
          także elementem maszyn w zakładach przemysłowych.
        </p>

        <p>
          Rozwiązania Alfa Laval promują nowoczesne i energooszczędne metody
          wykorzystania energii, bez względu na rodzaj źródła zasilania. Na
          przykład, w okresie letnim, gdy system ogrzewania budynku nie jest
          używany w 100%, nadwyżki energii mogą być wykorzystywane do ogrzewania
          basenów odkrytych. Wykorzystując odzysk ciepła, m.in. z procesów
          produkcyjnych do ogrzewania budynków, Alfa Laval umożiwia oszczędzanie
          energii i zmniejszenie kosztów.
        </p>

        <p>
          Wymienniki ciepła Alfa Laval z certyfikatem wydajności zapewniają
          niezawodne działanie.
        </p>
      </Products>
    </Container>
  </Layout>
)

export default Produkty
